<template>
  <v-app-bar id="app-bar" dark fixed app elevation="12" height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      text
      fab
      small
      @click="setDrawer(!drawer)"
      color="accent" 
    >
      <v-icon v-if="value">mdi-view-quilt</v-icon>
      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-light"
      v-text="$route.name"
      color="accent" 
    />

    <v-spacer />

    <div class="mx-3" />

 
    <v-btn color="accent"  class="ml-4" min-width="0" text to="/iven/Dashboard">
      <v-icon>mdi-chart-multiple</v-icon>Dashboard
    </v-btn>
    <!--  <v-btn class="ml-4" min-width="0" text to="/iven/home_menu">
      <v-icon>mdi-home</v-icon>เมนูระบบ
    </v-btn> -->

    <v-btn color="warning"  class="ml-12" min-width="0" text @click="logout()">
      <v-icon>mdi-exit-to-app</v-icon>ออกจากระบบ
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboarduserAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one"
    ]
  }),

  computed: {
    ...mapState(["drawer"])
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Prompt");

#app-bar {
  background-color: #f7f7f7;
  border-bottom: 3px solid grey;
  color: rgb(72, 71, 71);
}
</style>
